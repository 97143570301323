import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'seguros',
    loadChildren: './featured-modules/covid-oh/covid-oh.module#CovidOhModule',
  },
  {
    path: 'planes',
    loadChildren:
      './featured-modules/planes-oh/planes-oh.module#PlanesOhModule',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
