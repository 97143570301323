import { AseguradoModel } from './../../models/asegurado-model';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThisReceiver } from '@angular/compiler';
import { TitleCasePipe } from '../../pipes/titleCase/title-case.pipe';
import { UtilService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-form-add-pacient',
  templateUrl: './form-add-pacient.component.html',
  styleUrls: ['./form-add-pacient.component.scss'],
})
export class FormAddPacientComponent implements OnInit {
  @Input() initial: AseguradoModel;
  @Output() resultado: EventEmitter<AseguradoModel> = new EventEmitter();
  @Output() back: EventEmitter<number> = new EventEmitter();

  public searchType: boolean = false;
  public data: AseguradoModel = {
    id: 0,
    user_id: null,
    user_numDoc: null,
    user_nom1: '',
    user_ape1: '',
    user_ape2: '',
    user_fech_nac: '',
    user_email: '',
    user_plan: null,
  };

  public validator: Boolean = false;

  constructor(private utilService: UtilService) {}

  ngOnInit(): void {
    this.data = this.initial;
  }

  addPacient(): void {
    if (this.data.user_email.includes('@')) {
      this.resultado.emit(this.data);
    } else {
      // console.log('no cumple con la condición');
    }
  }

  onChange(text: string) {
    // console.log(text);
    // if (text.length === 8) {
    //   this.searchType = true;
    //   return this.utilService.listUser(text).subscribe(
    //     (datos) => {
    //       if (datos != null) {
    //         this.data.id = datos['user_id'];
    //         this.data.user_nom1 = new TitleCasePipe().transform(
    //           datos['user_nom1']
    //         );
    //         this.data.user_ape1 = new TitleCasePipe().transform(
    //           datos['user_ape1']
    //         );
    //         this.data.user_ape2 = new TitleCasePipe().transform(
    //           datos['user_ape2']
    //         );
    //         this.data.user_email = new TitleCasePipe().transform(
    //           datos['user_email']
    //         );
    //       }
    //       this.searchType = false;
    //     },
    //     (error) => {
    //       this.searchType = false;
    //     }
    //   );
    // }
  }

  validators(): Boolean {
    let value = (this.validator =
      `${this.data.user_numDoc}`.length > 6 &&
      this.data.user_nom1.length > 1 &&
      this.data.user_ape1.length > 2 &&
      this.data.user_ape2.length > 2 &&
      this.data.user_fech_nac !== '' &&
      this.data.user_email.includes('@') &&
      this.data.user_email.includes('.'));
    return value;
  }

  cancel(): void {
    this.back.emit(this.initial.user_id);
  }
}
