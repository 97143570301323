<div class="column">
  <mat-label>DNI</mat-label>
  <mat-form-field appearance="outline">
    <input
      matInput
      maxlength="8"
      type="tel"
      (ngModelChange)="onChange($event)"
      [(ngModel)]="data.user_numDoc"
      required
    />

    <div class="search" *ngIf="searchType" matSuffix>
      <div class="lds-dual-ring"></div>
    </div>
  </mat-form-field>

  <mat-label>Nombres</mat-label>
  <mat-form-field appearance="outline">
    <input matInput type="text" [(ngModel)]="data.user_nom1" required />
  </mat-form-field>

  <mat-label>Apellido Paterno</mat-label>
  <mat-form-field appearance="outline">
    <input matInput type="text" [(ngModel)]="data.user_ape1" required />
  </mat-form-field>

  <mat-label>Apellido Materno</mat-label>
  <mat-form-field appearance="outline">
    <input matInput type="text" [(ngModel)]="data.user_ape2" required />
  </mat-form-field>

  <mat-label>Fecha de nacimiento</mat-label>
  <mat-form-field appearance="outline" disabled (click)="picker.open()">
    <input
      matInput
      [matDatepicker]="picker"
      type="text"
      [(ngModel)]="data.user_fech_nac"
      readonly
      required
    />
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-label>Correo electrónico</mat-label>
  <mat-form-field appearance="outline">
    <input matInput type="email" [(ngModel)]="data.user_email" required />
  </mat-form-field>

  <div class="row">
    <p></p>
    <div class="row-simple">
      <button mat-button (click)="cancel()" class="blue-button-text">
        CANCELAR
      </button>
      <button
        mat-raised-button
        color="warn"
        class="blue-button"
        (click)="addPacient()"
        [disabled]="validators() === false"
      >
        GUARDAR
      </button>
    </div>
  </div>
</div>
