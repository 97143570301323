import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { SelectPlanComponent } from './components/select-plan/select-plan.component';
import { PlanIndividualComponent } from './components/plan-individual/plan-individual.component';
import { PlanFamiliarComponent } from './components/plan-familiar/plan-familiar.component';
import { PlanOtrosComponent } from './components/plan-otros/plan-otros.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'select-plan',
    component: SelectPlanComponent,
  },
  {
    path: 'plan-individual',
    component: PlanIndividualComponent,
  },

  {
    path: 'plan-familiar',
    component: PlanFamiliarComponent,
  },

  {
    path: 'plan-otros',
    component: PlanOtrosComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlanesOhRoutingModule {}
