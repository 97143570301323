import { Component, OnInit, Input } from '@angular/core';
import { UtilService } from 'src/app/shared/services/utils/utils.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss'],
})
export class SelectPlanComponent implements OnInit {
  public plan: string;
  public precio: number;

  constructor(private router: Router, private servicio: UtilService) {}

  ngOnInit(): void {
    switch (this.servicio.getPrice().toString()) {
      case '35':
        {
          this.plan = 'Telesalud Oh';
          this.precio = 35;
        }
        break;
      case '55':
        {
          this.plan = 'Telesalud Full';
          this.precio = 55;
        }
        break;
    }
  }

  go() {
    const plan = document
      .getElementsByClassName('mat-radio-checked')[0]
      .getAttribute('value');
    this.servicio.setTipoPlan(plan);
    this.router.navigate([`planes/${plan}`]);
  }
}
