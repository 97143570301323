import { AseguradoFinal, SendPayment } from './../models/asegurado-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AseguradoModel } from '../models/asegurado-model';
import { Pagos } from '../models/payment-model';
import { Globals } from '../utils/global';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'POST, OPTIONS',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
  });

  sendDataPlan(pagos: Pagos, data: User[]) {
    const url_api = `${Globals.devUrl}/register`;
    return this.http.post(url_api, { pagos, data }, { headers: this.headers });
  }

  sendDataCovid(
    pagos: Pagos,
    data: Array<AseguradoModel>,
    infoPersona: AseguradoFinal
  ) {
    var dataFinal: Array<AseguradoFinal> = [];
    for (let i = 0; i < data.length; i++) {
      dataFinal.push({
        user_id: (data[i].user_numDoc = infoPersona.user_numDoc
          ? infoPersona.user_id
          : data[i].id), //será 0 cuando no existe el asegurado en bd
        user_tipoDoc_id: data[i].user_numDoc.toString().length === 8 ? 2 : 1, //2:dni, 1:carnet extrangería
        user_numDoc: data[i].user_numDoc.toString(),
        user_nom1: data[i].user_nom1,
        user_nom2: '',
        user_ape1: data[i].user_ape1,
        user_ape2: data[i].user_ape2,
        user_direcc: '',
        user_telf: '',
        user_email: data[i].user_email,
        user_ubg: '',
        user_fech_nac: '', //mantenerlo vacio  si no tiene data
        // user_fech_nac: data[i].user_fech_nac, //mantenerlo vacio  si no tiene data
        user_sexo: '', //mantenerlo vacio si no tiene data
        user_tipo: '2', // 1: contratante, 2: afiliado
        user_plan: infoPersona.user_plan, // PLANES-> 255:TELESALUD OH , 256: TELESALUD FULL , 254:PRUEBAS ANTIGENAS
        user_idproveedor: parseInt(infoPersona.user_idproveedor.toString()), //mantener en 0  si no tiene data
        user_parent: 1,
      });
    }

    infoPersona.user_ubg = '';

    dataFinal.push(infoPersona);

    let info: SendPayment = {
      data: dataFinal,
      pagos: pagos,
    };

    // console.log(info);

    const url_api = `${Globals.devUrl}/register`;
    return this.http.post(url_api, info, { headers: this.headers });
  }
}
