import { Component, Input } from '@angular/core';

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
})
export class SwiperComponent {
  @Input() page: string;

  constructor() {
    this.page = '';
  }

  public PlansData: Array<string> = [
    '/assets/png/planes-swiper-1.png',
    '/assets/png/planes-swiper-2.png',
    '/assets/png/planes-swiper-3.png',
  ];

  public PlansDataResp: Array<string> = [
    '/assets/png/planes-swiper-resp-1.png',
    '/assets/png/planes-swiper-resp-2.png',
    '/assets/png/planes-swiper-resp-3.png',
  ];

  public TestCovidData: Array<string> = [
    '/assets/png/covid-swiper-1.png',
    '/assets/png/covid-swiper-2.png',
    '/assets/png/covid-swiper-3.jpg',
  ];

  public TestCovidDataResp: Array<string> = [
    '/assets/png/planes-swiper-resp-1.png',
    '/assets/png/planes-swiper-resp-2.png',
    '/assets/png/planes-swiper-resp-3.png',
  ];
}
