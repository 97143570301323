import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Ubigeo, User } from '../../models/user.model';
import { DialogService } from '../../services/dialog/dialog.service';
import { UtilService } from '../../services/utils/utils.service';
import { ModalUbigeoComponent } from '../modal-ubigeo/modal-ubigeo.component';

@Component({
  selector: 'app-modal-edit-afiliado',
  templateUrl: './modal-edit-afiliado.component.html',
  styleUrls: ['./modal-edit-afiliado.component.scss'],
})
export class ModalEditAfiliadoComponent implements OnInit {
  public precio: number;

  public id_plan: number;

  public loading: boolean = false;

  public afiliado: User = {
    user_id: 0,
    user_tipoDoc_id: 0,
    user_numDoc: '',
    user_nom1: '',
    user_nom2: '',
    user_ape1: '',
    user_ape2: '',
    user_direcc: '',
    user_telf: '',
    user_email: '',
    user_ubg: '',
    user_fech_nac: '',
    user_sexo: '',
    user_tipo: '',
    user_plan: 0,
    user_idprovedor: 0,
  };

  public fecha: Date;

  public ubigeo: Ubigeo = {
    id: '',
    departamento: '',
    provincia: '',
    distrito: '',
  };

  public ubigeoString: string;

  public formGroup: FormGroup;

  public max: string = '';

  public tipodoc: string = '';

  constructor(
    private service: UtilService,
    public dialogRef: MatDialogRef<ModalEditAfiliadoComponent>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.ubigeoString = this.data['ubigeo'];

    this.afiliado = this.data['user'];

    this.fecha = new Date(`${this.afiliado.user_fech_nac}T00:00:00`);

    this.formGroup = this._formBuilder.group({
      tipodoc: ['', Validators.required],
      dni: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.email]],
      email: ['', Validators.required],
      fecha: ['', Validators.required],
      nomb1: ['', Validators.required],
      ape1: ['', Validators.required],
      ape2: ['', Validators.required],
      direccion: ['', Validators.required],
      ubigeo: ['', Validators.required],
    });
  }

  set_max() {
    this.afiliado.user_numDoc = '';

    switch (this.tipodoc) {
      case '2':
        this.max = '8';
        break;
      case '3':
      case '4':
        this.max = '12';
        break;
      case '1':
        this.max = '20';
        break;
      case '5':
        this.max = '10';
        break;
    }
  }

  setIdPlan(precio: number) {
    switch (precio) {
      case 35:
        this.id_plan = 255;
        // console.log(this.id_plan);
        break;
      case 55:
        this.id_plan = 256;
        break;
    }
  }

  validarDNI() {
    if (
      this.afiliado.user_numDoc.length === parseInt(this.max) ||
      this.afiliado.user_numDoc.length == 11
    ) {
      this.loading = true;
      this.service
        .listUser(this.afiliado.user_numDoc, this.id_plan, 3)
        .subscribe(
          (datos) => {
            this.loading = false;
            if (datos['user_numDoc'] != '') {
              this.afiliado.user_id = datos['user_id'];
              this.afiliado.user_tipoDoc_id = datos['user_tipoDoc_id'];
              this.afiliado.user_numDoc = datos['user_numDoc'];
              this.afiliado.user_nom1 = datos['user_nom1'];
              this.afiliado.user_nom2 = datos['user_nom2'];
              this.afiliado.user_ape1 = datos['user_ape1'];
              this.afiliado.user_ape2 = datos['user_ape2'];
              this.afiliado.user_direcc = datos['user_direcc'];
              this.afiliado.user_telf = datos['user_telf'];
              this.afiliado.user_email = datos['user_email'];
              this.afiliado.user_ubg =
                datos['user_ubg'] == '' ? '150101' : datos['user_ubg'];
              this.afiliado.user_fech_nac = datos['user_fech_nac'];

              this.fecha = new Date(`${this.afiliado.user_fech_nac}T00:00:00`);

              // console.log(this.fecha);

              this.afiliado.user_sexo = datos['user_sexo'];
              this.afiliado.user_plan = this.precio == 55 ? 256 : 255;
              this.afiliado.user_tipo = '1';
              // console.log(this.user);
            } else {
              this.loading = false;
            }
            this.afiliado.user_plan = this.precio == 55 ? 256 : 255;
            this.afiliado.user_tipoDoc_id = parseInt(this.tipodoc);
            // console.log(this.user);
          },
          (error) => {
            this.loading = false;
            this.dialogService.material('recurrent', 'planes');
          }
        );
    }
  }

  modal() {
    const dialogRef = this.dialog.open(ModalUbigeoComponent, {
      width: '80%',
      data: this.ubigeo,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ubigeo = result;
      this.ubigeoString = `${this.ubigeo.departamento}/${this.ubigeo.provincia}/${this.ubigeo.distrito}`;
      this.afiliado.user_ubg = this.ubigeo.id;
      // console.log(this.ubigeo);
    });
  }

  close(): void {
    this.dialogRef.close({ data: this.data['user'] });
  }

  validator(): boolean {
    let value = this.formGroup.status == 'VALID';

    return value;
  }

  setfecha() {
    let t = this.fecha.toLocaleDateString().split('/');

    let dia = t[0].length === 1 ? `0${t[0]}` : `${t[0]}`;
    let mes = t[1].length === 1 ? `0${t[1]}` : `${t[1]}`;
    let anio = t[2];

    this.afiliado.user_fech_nac = `${anio}${mes}${dia}`;

    // console.log(this.afiliado);
  }

  aea() {
    let t = this.fecha.toLocaleDateString().split('/');

    let dia = t[0].length === 1 ? `0${t[0]}` : `${t[0]}`;
    let mes = t[1].length === 1 ? `0${t[1]}` : `${t[1]}`;
    let anio = t[2];

    this.afiliado.user_fech_nac = `${anio}${mes}${dia}`;

    if (
      this.afiliado.user_numDoc == '' ||
      this.afiliado.user_nom1 == '' ||
      this.afiliado.user_ape1 == '' ||
      this.afiliado.user_ape2 == '' ||
      this.afiliado.user_direcc == '' ||
      this.afiliado.user_ubg == ''
    ) {
      alert('Completa todos los datos necesarios uwu');
    } else {
      this.dialogRef.close({ data: this.afiliado });
      // console.log(this.data);
    }
  }
}
