import { Component, OnInit } from '@angular/core';
import { Ubigeo, User } from 'src/app/shared/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from 'src/app/shared/services/utils/utils.service';
import { ModalUbigeoComponent } from 'src/app/shared/material/modal-ubigeo/modal-ubigeo.component';
import { ModalAddAfiliadoComponent } from 'src/app/shared/material/modal-add-afiliado/modal-add-afiliado.component';
import { ValidateCardPipe } from 'src/app/shared/pipes/validateCard/validate-card.pipe';
import { ModalEditAfiliadoComponent } from 'src/app/shared/material/modal-edit-afiliado/modal-edit-afiliado.component';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentPayment } from 'src/app/shared/models/asegurado-model';
import { Pagos } from 'src/app/shared/models/payment-model';
import { ValidateMYPipe } from 'src/app/shared/pipes/validate-my.pipe';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { PaymentService } from 'src/app/shared/services/payment.service';

@Component({
  selector: 'app-plan-familiar',
  templateUrl: './plan-familiar.component.html',
  styleUrls: ['./plan-familiar.component.scss'],
})
export class PlanFamiliarComponent implements OnInit {
  public precio: number;

  public mmyyyy: boolean = false;
  public loading: boolean = false;
  public cargando: boolean = false;

  public envio: boolean = false;
  public poliza: boolean = false;
  public renovacion: boolean = false;

  public fecha: Date;

  public creditCard: DocumentPayment = {
    number_credit: null,
    type: '',
    fullname: '',
    expiration: '',
    cvv: null,
  };

  public user: User = {
    user_id: 0,
    user_tipoDoc_id: 2,
    user_numDoc: '',
    user_nom1: '',
    user_nom2: '',
    user_ape1: '',
    user_ape2: '',
    user_direcc: '',
    user_telf: '',
    user_email: '',
    user_ubg: '',
    user_fech_nac: '',
    user_sexo: '',
    user_tipo: '1',
    user_plan: 0,
    user_idprovedor: 0,
  };

  public afiliado: User = {
    user_id: 0,
    user_tipoDoc_id: 2,
    user_numDoc: '',
    user_nom1: '',
    user_nom2: '',
    user_ape1: '',
    user_ape2: '',
    user_direcc: '',
    user_telf: '',
    user_email: '',
    user_ubg: '',
    user_fech_nac: '',
    user_sexo: '',
    user_tipo: '2',
    user_plan: 0,
    user_idprovedor: 0,
  };

  public afiliados: User[] = [];

  public q: number = 0;

  public dataFinal;

  public id_plan;

  public ubigeo: Ubigeo = {
    id: '',
    departamento: '',
    provincia: '',
    distrito: '',
  };

  public ubigeoString: string;
  public nacimientoString: string;

  public tipodoc: string = '';
  public max: string = '';

  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;

  constructor(
    private service: UtilService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private dialogService: DialogService,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.precio = this.service.getPrice();

    this.setIdPlan(this.precio);

    this.firstFormGroup = this._formBuilder.group({
      tipodoc: ['', Validators.required],
      dni: ['', Validators.required],
      nombre1: ['', Validators.required],
      apellido1: ['', Validators.required],
      apellido2: ['', Validators.required],
      fecha: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      telefono: ['', Validators.required],
      locacion: ['', Validators.required],
      direccion: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      numbercredit: ['', [Validators.required, Validators.minLength(12)]],
      fullname: ['', Validators.required],
      expiration: ['', [Validators.required, Validators.minLength(5)]],
      cvv: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  getTypeCreditCard(): void {
    this.creditCard.type = new ValidateCardPipe().transform(
      this.creditCard.number_credit.toString()
    );
    // // console.log(this.creditCard.number_credit);
    // // console.log(this.creditCard.type);
  }

  set_max() {
    this.user.user_numDoc = '';

    switch (this.tipodoc) {
      case '2':
        this.max = '8';
        break;
      case '3':
      case '4':
        this.max = '12';
        break;
      case '1':
        this.max = '20';
        break;
      case '5':
        this.max = '10';
        break;
    }
  }

  setfecha() {
    let t = this.fecha.toLocaleDateString().split('/');

    let dia = t[0].length === 1 ? `0${t[0]}` : `${t[0]}`;
    let mes = t[1].length === 1 ? `0${t[1]}` : `${t[1]}`;
    let anio = t[2];

    this.user.user_fech_nac = `${anio}-${mes}-${dia}`;

    // console.log(this.user);
  }

  setIdPlan(precio: number) {
    switch (precio) {
      case 35:
        this.id_plan = 255;
        break;
      case 55:
        this.id_plan = 256;
        break;
    }
  }

  validateMMYY() {
    if (this.creditCard.expiration.length == 2) {
      this.creditCard.expiration += '/';
    }
  }

  validarDNI() {
    if (
      this.user.user_numDoc.length === parseInt(this.max) ||
      this.user.user_numDoc.length == 11
    ) {
      this.loading = true;
      return this.service
        .listUser(this.user.user_numDoc, this.id_plan, 3)
        .subscribe(
          (datos) => {
            this.loading = false;
            if (datos['user_numDoc'] != '') {
              // console.log(datos);
              this.user.user_id = datos['user_id'];
              this.user.user_tipoDoc_id = datos['user_tipoDoc_id'];
              this.user.user_numDoc = datos['user_numDoc'];
              this.user.user_nom1 = datos['user_nom1'];
              this.user.user_nom2 = datos['user_nom2'];
              this.user.user_ape1 = datos['user_ape1'];
              this.user.user_ape2 = datos['user_ape2'];
              this.user.user_direcc = datos['user_direcc'];
              this.user.user_telf = datos['user_telf'];
              this.user.user_email = datos['user_email'];
              this.user.user_ubg =
                datos['user_ubg'] == '' ? '150101' : datos['user_ubg'];
              this.user.user_fech_nac = datos['user_fech_nac'];

              this.fecha = new Date(`${this.user.user_fech_nac}T00:00:00`);

              this.user.user_sexo = datos['user_sexo'];
              // this.user.user_plan = 98;
              this.user.user_plan = this.precio == 55 ? 256 : 255;
              this.user.user_tipo = '1';
              this.validarUbigeo(this.user.user_numDoc);
              this.afiliado = this.user;
            } else {
              this.loading = false;
            }
            this.user.user_plan = this.precio == 55 ? 256 : 255;
            this.user.user_tipoDoc_id = parseInt(this.tipodoc);
            // console.log(this.user);
          },
          (error) => {
            this.loading = false;
            this.dialogService.material('recurrent', 'planes');
          }
        );
    }
  }

  validarUbigeo(dni: string) {
    return this.service.listUbigeo(dni).subscribe(
      (datos) => {
        if (datos != null) {
          this.ubigeo.departamento = datos['departamento'];
          this.ubigeo.provincia = datos['provincia'];
          this.ubigeo.distrito = datos['distrito'];
          if (this.afiliado.user_ubg != '') {
            this.ubigeoString = `${this.ubigeo.departamento}/${this.ubigeo.provincia}/${this.ubigeo.distrito}`;
          }
        }
      },
      (error) => {}
    );
  }

  modal() {
    const dialogRef = this.dialog.open(ModalUbigeoComponent, {
      width: '80%',
      data: this.ubigeo,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ubigeo = result;
      this.user.user_ubg = this.ubigeo.id;
      this.afiliado.user_ubg = this.user.user_ubg;
      this.ubigeoString = `${this.ubigeo.departamento}/${this.ubigeo.provincia}/${this.ubigeo.distrito}`;
      this.agregar();
    });
  }

  modalAdd() {
    const dialogRef = this.dialog.open(ModalAddAfiliadoComponent, {
      width: '90%',
      height: 'auto',
      data: this.afiliados,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.q = this.afiliados.length;
    });
  }

  agregar() {
    if (this.afiliado.user_ubg != '' && this.afiliados.length === 0) {
      this.afiliado = this.user;
      this.afiliados.push(this.afiliado);
      this.q = this.afiliados.length;
    }
    // console.log(this.afiliados);
  }

  modalEdit(user: User, i: number) {
    const dialogRef = this.dialog.open(ModalEditAfiliadoComponent, {
      width: '90%',
      height: 'auto',
      disableClose: true,
      data: {
        user: user,
        ubigeo: this.ubigeoString,
        nacimiento: this.nacimientoString,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.afiliados[i] = result.data;
      // console.log(this.afiliados);
    });
  }

  back() {
    window.history.back();
  }

  delete(user: User) {
    this.afiliados.splice(this.afiliados.indexOf(user), 1);
    this.q = this.afiliados.length;
    // console.log(this.afiliados);
  }

  comprar(): void {
    if (
      this.creditCard.number_credit > 12 &&
      this.creditCard.type.length > 3 &&
      this.creditCard.fullname.length > 2 &&
      this.creditCard.expiration.length === 5 &&
      this.creditCard.cvv.toString().length >= 3
    ) {
      let pagos: Pagos = {
        // codplan: this.precio == 55 ? '100' : '99',
        codplan:
          this.precio == 55
            ? `Telesalud-Full-${this.q}`
            : `Telesalud-Oh-${this.q}`,
        email: this.user.user_email,
        name: `${this.creditCard.fullname}`,
        document: this.user.user_numDoc,
        number: this.creditCard.number_credit.toString(),
        expMonth: this.creditCard.expiration.split('/')[0],
        expYear: `20${this.creditCard.expiration.split('/')[1]}`,
        typeCard: this.creditCard.type,
        typeDocument: 'DNI',
        cvv: this.creditCard.cvv.toString(),
        line1: this.user.user_direcc,
        postalCode: '+51',
        city: this.ubigeo.distrito,
        state: this.ubigeo.distrito,
        country: 'PE',
        phone: this.user.user_telf,
        quantity: this.q.toString(),
      };

      this.addContratante();

      let data: User[] = this.dataFinal;

      this.dialogService.material('loading', 'planes');
      // console.log({ data, pagos });

      this.paymentService.sendDataPlan(pagos, data).subscribe(
        (datos) => {
          this.dialogService.close();

          datos['status']
            ? this.dialogService.material('thanks', 'planes')
            : this.dialogService.material('error', 'planes');
        },
        (error) => {
          this.dialogService.close();
          this.dialogService.material('error', 'planes');
          this.dataFinal.pop();
          // console.log(data);
        }
      );
    } else {
      this.dialogService.material('error', 'planes');
    }
  }

  firstValidator(): boolean {
    let value = this.firstFormGroup.status == 'VALID';

    return value;
  }

  secondValidator(): boolean {
    let value = this.secondFormGroup.status == 'VALID';

    return value;
  }

  thirdValidator(): boolean {
    // let value =
    //   this.envio &&
    //   this.poliza &&
    //   this.renovacion &&
    //   `${this.creditCard.number_credit}`.length > 14 &&
    //   this.creditCard.type.length > 3 &&
    //   this.creditCard.fullname.length > 4 &&
    //   this.creditCard.expiration.length === 5 &&
    //   `${this.creditCard.cvv}`.length > 2 &&
    //   this.creditCard.cvv !== null;

    let value =
      this.renovacion &&
      this.poliza &&
      this.envio &&
      this.thirdFormGroup.status == 'VALID';

    return value;
  }

  conditions(condicion: string) {
    switch (condicion) {
      case 'envio':
        this.envio = !this.envio;

        break;
      case 'poliza':
        this.poliza = !this.poliza;

        break;

      case 'renovacion':
        this.renovacion = !this.renovacion;

        break;
    }
  }

  ola(condicion: string) {
    this.dialogService.material(`${condicion}`, 'planes');
  }

  addContratante() {
    // console.log(this.user);

    let t = this.user.user_fech_nac.split('-');

    this.dataFinal = this.afiliados;
    this.dataFinal.push({
      user_id: this.user.user_id,
      user_tipoDoc_id: this.user.user_tipoDoc_id,
      user_numDoc: this.user.user_numDoc,
      user_nom1: this.user.user_nom1,
      user_nom2: this.user.user_nom2,
      user_ape1: this.user.user_ape1,
      user_ape2: this.user.user_ape2,
      user_direcc: this.user.user_direcc,
      user_telf: this.user.user_telf,
      user_email: this.user.user_email,
      user_ubg: this.user.user_ubg,
      user_fech_nac: `${t[0]}${t[1]}${t[2]}`,
      user_sexo: '',
      user_tipo: '2',
      user_plan: this.precio == 55 ? 256 : 255,
      user_ideprovedor: 0,
    });
  }

  pop() {
    if (this.afiliados.length != 1) {
      this.afiliados.splice(this.afiliados.length - 1, 1);
      this.q = this.afiliados.length;
      // console.log(this.afiliados);
    }
  }
}
