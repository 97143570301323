import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Dialog } from '../../models/payment-model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { message: string; page: string }
  ) {}

  ngOnInit(): void {
    // console.log(this.data.message);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  aceptar() {
    this.router.navigate([``]);
    this.dialogRef.close();
  }
}
