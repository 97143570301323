import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CovidService } from 'src/app/shared/services/covid/covid.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  num = 1;

  public sintomas = [
    {
      icon: '/assets/icons/fiebre.svg',
      title: 'Fiebre alta',
      desc: 'Temperatura mayor a 38º grados.',
    },
    {
      icon: '/assets/icons/dolor-de-cabeza.svg',
      title: 'Dolor corporal',
      desc: 'Cansancio y malestar en todo el cuerpo.',
    },
    {
      icon: '/assets/icons/dolor-de-garganta.svg',
      title: 'Tos seca',
      desc: 'Dolor al toser y digerir alimentos.',
    },
    {
      icon: '/assets/icons/nariz-que-moquea.svg',
      title: 'Dolor en el pecho',
      desc: 'Dificultad para respirar.',
    },
  ];

  plus(): void {
    this.num++;
  }

  minus(): void {
    if (this.num === 1) {
      this.num = 1;
    } else {
      this.num--;
    }
  }

  comprar(): void {
    this.covidService.setItem(this.num);
    this.router.navigate(['/seguros/checkout']);
    // alert(`Número de pruebas covid: ${this.num}`);
    // this.router.navigateByUrl('/seguros/checkout', {
    //   state: { datos: this.num },
    // });
  }

  constructor(private router: Router, private covidService: CovidService) {}

  ngOnInit(): void {}
}
