import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/shared/services/utils/utils.service';
import { Router } from '@angular/router';

import {
  Coberturas,
  CoberturasClasic,
} from 'src/app/shared/models/cobertura.model';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public coberturas = Coberturas;
  public coberturasClasic = CoberturasClasic;
  public beneficios = [
    {
      icon: '/assets/icons/red-medica.svg',
      title: 'Red Médica',
      desc: 'Más de 120 clínicas y centros médicos a nivel nacional.',
    },
    {
      icon: '/assets/icons/citas-medicas.svg',
      title: 'Farmacias',
      desc: 'Solicita tus medicamentos en Inkafarma o Boticas Perú.',
    },
    {
      icon: '/assets/icons/farmacias.svg',
      title: 'Citas médicas',
      desc: 'Atención médica en menos de 24 horas.',
    },
    {
      icon: '/assets/icons/atencion-online.svg',
      title: 'Atención online',
      desc: 'Teleconsultas desde la comodidad de tu hogar.',
    },
  ];

  constructor(
    private router: Router,
    private servicio: UtilService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  go(tipo: string) {
    switch (tipo) {
      case 'clasic':
        {
          this.servicio.setPrice(35);
        }
        break;
      case 'premium':
        {
          this.servicio.setPrice(55);
        }
        break;
    }
    this.router.navigate(['/planes/select-plan']);
  }

  modal() {
    this.dialogService.material('thanks', '');
  }
}
