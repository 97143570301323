import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { PlanesOhModule } from './featured-modules/planes-oh/planes-oh.module';
import { CovidOhModule } from './featured-modules/covid-oh/covid-oh.module';
import { ValidateCardPipe } from './shared/pipes/validateCard/validate-card.pipe';
import { TitleCasePipe } from './shared/pipes/titleCase/title-case.pipe';
import { PhonePipe } from './shared/pipes/validation/phone.pipe';
import { ValidateMYPipe } from './shared/pipes/validate-my.pipe';

@NgModule({
  declarations: [AppComponent, ValidateCardPipe, TitleCasePipe, PhonePipe, ValidateMYPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    CovidOhModule,
    PlanesOhModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
