<app-swiper [page]="'planes'"></app-swiper>
<section class="beneficios">
  <h2>¡Lo más importante para nosotros eres tú!</h2>
  <!-- <button (click)="modal()">TOCAME</button> -->
  <p>
    Conoce aquí todos los beneficios que tenemos para cuidar tu salud. Exclusivo
    para todos los clientes de tarjeta Oh!
  </p>
  <div class="beneficios-container">
    <div class="beneficio" *ngFor="let beneficio of beneficios">
      <img src="{{ beneficio.icon }}" alt="" />
      <h3>{{ beneficio.title }}</h3>
      <p>{{ beneficio.desc }}</p>
    </div>
  </div>
</section>
<section class="planes">
  <h2>Protege tu salud y a tu familia en 1 minuto</h2>
  <p>Descubre los descuentos con tu tajerta Oh!</p>
  <div class="planes-table">
    <div class="cabecera">
      <div class="sized"></div>
      <div class="clasico">
        <div class="title">Telesalud Oh</div>
        <h3>Beneficio exclusivo</h3>
        <img src="/assets/icons/oh-logo.svg" alt="" />
      </div>
      <div class="premium">
        <div class="resalt">RECOMENDADO</div>
        <div class="title">Telesalud Full</div>
        <h3>Beneficio exclusivo</h3>
        <img src="/assets/icons/oh-logo.svg" alt="" />
      </div>
    </div>
    <div class="precios">
      <div class="text"><h3>Coberturas</h3></div>
      <div class="precio">
        <h1>S./ 35.00</h1>
        <h3>Antes: <del>S./ 50</del></h3>
      </div>
      <div class="precio">
        <h1>S./ 55.00</h1>
        <h3>Antes: <del>S./ 70</del></h3>
      </div>
    </div>
    <div class="coberturas">
      <div class="coberturas-table">
        <div class="cobertura" *ngFor="let cobertura of coberturas">
          <div class="detalle">
            <img src="{{ cobertura.icon }}" alt="" />
            <p>{{ cobertura.title }}</p>
            <img
              matTooltip="{{ cobertura.text }}"
              class="info"
              *ngIf="cobertura.info != ''"
              src="{{ cobertura.info }}"
              alt=""
            />
          </div>
          <div class="precio-basic">
            <img src="{{ cobertura.clasico }}" alt="" />
          </div>
          <div class="precio-premium">
            <img src="{{ cobertura.premium }}" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="botones">
      <div class="sized"></div>
      <div class="comprar">
        <button (click)="go('clasic')">COMPRAR</button>
      </div>
      <div class="comprar">
        <button (click)="go('premium')">COMPRAR</button>
      </div>
    </div>
  </div>
  <div class="planes-table-resp">
    <div class="prueba-container">
      <div class="resalt">RECOMENDADO</div>
      <h2>Telesalud Full</h2>
      <p class="subtitle">Beneficio exclusivo</p>
      <img src="/assets/icons/oh-logo.svg" alt="" />
      <h1>S./ 55.00</h1>
      <h3>Antes: <del>S./ 70</del></h3>
      <div class="beneficios">
        <p *ngFor="let item of coberturas">{{ item.title }}</p>
      </div>
      <button class="comprar" (click)="go('premium')">COMPRAR</button>
    </div>
    <div class="prueba-container">
      <h2>Telesalud Oh</h2>
      <p class="subtitle">Beneficio exclusivo</p>
      <img src="/assets/icons/oh-logo.svg" alt="" />
      <h1>S./ 35.00</h1>
      <h3>Antes: <del>S./ 50</del></h3>
      <div class="beneficios">
        <p *ngFor="let item of coberturasClasic">{{ item.title }}</p>
      </div>
      <button class="comprar" (click)="go('clasic')">COMPRAR</button>
    </div>
  </div>
</section>

<section class="baner">
  <h2>Reserva tu teleconsulta hoy mismo</h2>
  <p>La mejor atención para toda tu familia</p>
  <img src="/assets/png/planes-baner.png" alt="" />
</section>

<section class="preguntas">
  <h2>Preguntas frecuentes</h2>
  <expanded [page]="'planes'"></expanded>
</section>
