<mat-horizontal-stepper labePosition="bottom" [linear]="true" #stepper>
  <mat-step [editable]="true" [stepControl]="firstFormGroup">
    <ng-template matStepLabel>Datos</ng-template>
    <form [formGroup]="firstFormGroup">
      <div class="container">
        <div class="container__first">
          <h2 class="title">Resumen de Compra</h2>
          <app-resumen-compra-plan
            [plan]="'otros'"
            [q]="q"
          ></app-resumen-compra-plan>
        </div>

        <div class="container__second">
          <h2 class="title">Contratante</h2>
          <div class="outlined-sec">
            <div class="outlined-sec--item">
              <mat-label>Tipo de documento</mat-label>
              <mat-form-field appearance="outline">
                <mat-select
                  [(ngModel)]="tipodoc"
                  (ngModelChange)="set_max()"
                  formControlName="tipodoc"
                >
                  <mat-option value="2">DNI</mat-option>
                  <mat-option value="4">PASAPORTE</mat-option>
                  <mat-option value="1">RUC</mat-option>
                  <mat-option value="3">CARNET DE EXTRANJERÍA</mat-option>
                  <mat-option value="5">CÉDULA DE INDENTIFICACIÓN</mat-option>
                </mat-select>
                <mat-error
                  *ngIf="firstFormGroup.controls.tipodoc.hasError('required')"
                >
                  Seleccione un tipo de documento.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Número de documento</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="user.user_numDoc"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
                (ngModelChange)="validarDNI()"
              >
                <input
                  matInput
                  type="number"
                  [maxlength]="max"
                  oninput=" if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  required
                  formControlName="dni"
                />
                <mat-icon matSuffix *ngIf="!loading">recent_actors</mat-icon>
                <div class="search" *ngIf="loading" matSuffix>
                  <div class="lds-dual-ring"></div>
                </div>
                <mat-error
                  *ngIf="firstFormGroup.controls.dni.hasError('required')"
                >
                  Por favor ingresa un número de documento válido.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Primer Nombre</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="user.user_nom1"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  [ngModel]="user.user_nom1 | titlecase"
                  matInput
                  type="text"
                  required
                  formControlName="nombre1"
                />
                <mat-error
                  *ngIf="firstFormGroup.controls.nombre1.hasError('required')"
                >
                  Por favor completa este campo.
                </mat-error>
                <mat-icon matSuffix>person</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Segundo Nombre</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  [ngModel]="user.user_nom2 | titlecase"
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-icon matSuffix>person</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Primer Apellido</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="user.user_ape1"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  matInput
                  type="text"
                  required
                  [ngModel]="user.user_ape1 | titlecase"
                  formControlName="apellido1"
                />
                <mat-error
                  *ngIf="firstFormGroup.controls.apellido1.hasError('required')"
                >
                  Por favor completa este campo.
                </mat-error>
                <mat-icon matSuffix>person</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Segundo Apellido</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="user.user_ape2"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  [ngModel]="user.user_ape2 | titlecase"
                  matInput
                  type="text"
                  required
                  formControlName="apellido2"
                />
                <mat-error
                  *ngIf="firstFormGroup.controls.apellido2.hasError('required')"
                  >Por favor completa este campo.
                </mat-error>
                <mat-icon matSuffix>person</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="botones">
            <div class="row-simple">
              <button class="btn-atras" mat-button (click)="back()">
                VOLVER
              </button>
              <button
                class="btn-siguiente"
                mat-raised-button
                matStepperNext
                [disabled]="!firstValidator()"
              >
                SIGUIENTE
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <ng-template matStepLabel>Datos secundarios</ng-template>
    <form onsubmit="null" [formGroup]="secondFormGroup">
      <div class="container">
        <div class="container__first">
          <h2 class="title">Resumen de Compra</h2>
          <app-resumen-compra-plan
            [plan]="'otros'"
            [q]="q"
          ></app-resumen-compra-plan>
        </div>

        <div class="container__second">
          <h2 class="title">Contratante</h2>
          <div class="outlined-sec">
            <div class="outlined-sec--item">
              <mat-label>Correo</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="user.user_email"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  matInput
                  type="text"
                  required
                  [(ngModel)]="user.user_email"
                  formControlName="email"
                />
                <mat-error
                  *ngIf="secondFormGroup.controls.email.hasError('email')"
                >
                  Por favor ingresa una dirección de correo válida.
                </mat-error>
                <mat-icon matSuffix>mail</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Teléfono</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="user.user_telf"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  matInput
                  type="tel"
                  maxlength="11"
                  required
                  [(ngModel)]="user.user_telf"
                  formControlName="telefono"
                />
                <mat-error
                  *ngIf="secondFormGroup.controls.telefono.hasError('required')"
                >
                  Por favor completa este campo.
                </mat-error>
                <mat-icon matSuffix>phone</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Locación</mat-label>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  readonly
                  required
                  (click)="modal()"
                  matInput
                  [(ngModel)]="ubigeoString"
                  formControlName="locacion"
                />
                <mat-error
                  *ngIf="secondFormGroup.controls.locacion.hasError('required')"
                  >Por favor completa este campo.
                </mat-error>
                <mat-icon matSuffix>room</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Dirección</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="user.user_direcc"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  matInput
                  type="text"
                  required
                  [(ngModel)]="user.user_direcc"
                  formControlName="direccion"
                />
                <mat-error
                  *ngIf="
                    secondFormGroup.controls.direccion.hasError('required')
                  "
                >
                  Por favor completa este campo.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="afiliados">
              <h3>Afiliados</h3>
              <div class="afiliados-container">
                <div class="afiliado-item" *ngFor="let i of afiliados">
                  <button class="btn-delete" (click)="delete(i)">x</button>
                  <div
                    class="afiliado"
                    (click)="modalEdit(i, afiliados.indexOf(i))"
                  >
                    {{ i.user_nom1.charAt(0) }}
                  </div>
                </div>
              </div>
              <button class="btn-add" mat-button (click)="modalAdd()">
                + Agregar Afiliado
              </button>
            </div>
          </div>
          <div class="botones">
            <p></p>
            <div class="row-simple">
              <button class="btn-atras" mat-button matStepperPrevious>
                VOLVER
              </button>
              <button
                class="btn-siguiente"
                mat-raised-button
                matStepperNext
                [disabled]="!secondValidator()"
              >
                SIGUIENTE
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup">
    <ng-template matStepLabel>Pagar</ng-template>
    <form onsubmit="null" [formGroup]="thirdFormGroup">
      <div class="container">
        <div class="container__first">
          <h2 class="title">Resumen de Compra</h2>
          <app-resumen-compra-plan
            [plan]="'otros'"
            [q]="q"
          ></app-resumen-compra-plan>
        </div>

        <div class="container__second">
          <h2 class="title">Contratante</h2>
          <div class="outlined-sec">
            <div class="flex-end">
              <img src="assets/images/g10.svg" alt="payu" />
            </div>

            <div class="outlined-sec--item"></div>

            <div class="outlined-sec--item">
              <mat-label>Métodos de pago</mat-label>
              <div class="row-simple">
                <img src="assets/icons/oh-logo.svg" alt="oh-logo" />
              </div>
            </div>

            <div class="outlined-sec--item"></div>
            <div class="outlined-sec--item">
              <mat-label
                >Número de tarjeta
                <img src="assets/images/visa.svg" alt="visa" />
                <img src="assets/images/Grupo 567.svg" alt="mastercard" />
                <img
                  src="assets/images/american-express.svg"
                  alt="american-express"
                />
                <img src="assets/images/diners-club.svg" alt="dinners-club" />
              </mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="creditCard.number_credit"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="getTypeCreditCard()"
                ngDefaultControl
              >
                <input
                  matInput
                  formControlName="numbercredit"
                  type="tel"
                  required
                  autocomplete="off"
                />

                <mat-error
                  *ngIf="
                    thirdFormGroup.controls.numbercredit.hasError('required')
                  "
                >
                  Por favor complete este campo.
                </mat-error>

                <span [ngSwitch]="creditCard.type" matSuffix>
                  <mat-icon *ngSwitchCase="'VISA'">
                    <img src="assets/images/visa.svg" alt="visa" />
                  </mat-icon>
                  <mat-icon *ngSwitchCase="'MASTERCARD'">
                    <img src="assets/images/Grupo 567.svg" alt="mastercard" />
                  </mat-icon>
                  <mat-icon *ngSwitchCase="'AMEX'">
                    <img
                      src="assets/images/american-express.svg"
                      alt="american"
                    />
                  </mat-icon>
                  <mat-icon *ngSwitchCase="'DINNERS'">
                    <img src="assets/images/diners-club.svg" alt="dinners" />
                  </mat-icon>
                  <mat-icon *ngSwitchDefault> account_balance_wallet </mat-icon>
                </span>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Nombre completo</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="creditCard.fullname"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  matInput
                  formControlName="fullname"
                  type="text"
                  required
                />
                <mat-error
                  *ngIf="thirdFormGroup.controls.fullname.hasError('required')"
                >
                  Por favor complete este campo.
                </mat-error>
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>Fecha de expiración (mm/YY)</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="creditCard.expiration"
                (ngModelChange)="validateMMYY()"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  [(ngModel)]="creditCard.expiration"
                  (ngModelChange)="validateMMYY()"
                  matInput
                  formControlName="expiration"
                  placeholder="09/20"
                  type="tel"
                  maxlength="5"
                  required
                  autocomplete="off"
                />
                <mat-error
                  *ngIf="
                    thirdFormGroup.controls.expiration.hasError('required')
                  "
                >
                  Por favor complete este campo.
                </mat-error>
                <mat-icon matSuffix>today</mat-icon>
              </mat-form-field>
            </div>
            <div class="outlined-sec--item">
              <mat-label>CVV</mat-label>
              <mat-form-field
                appearance="outline"
                [(ngModel)]="creditCard.cvv"
                [ngModelOptions]="{ standalone: true }"
                ngDefaultControl
              >
                <input
                  matInput
                  formControlName="cvv"
                  type="text"
                  maxlength="4"
                  required
                  autocomplete="off"
                />
                <mat-icon
                  matTooltip="Son los tres dígitos que está al reverso de la tarjeta"
                  matSuffix
                  >info</mat-icon
                >
                <mat-error
                  *ngIf="thirdFormGroup.controls.cvv.hasError('required')"
                >
                  Por favor complete este campo.
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <div>
                <mat-checkbox
                  [checked]="envio"
                  class="example-margin"
                  (change)="conditions('envio')"
                ></mat-checkbox>
                Acepto las
                <span
                  (click)="ola('envio')"
                  style="color: #d34659; cursor: pointer"
                >
                  Políticas de envío de comunicaciones comerciales.
                </span>
              </div>

              <div>
                <mat-checkbox
                  [checked]="poliza"
                  class="example-margin"
                  (change)="conditions('poliza')"
                >
                </mat-checkbox>
                Acepto la
                <span
                  (click)="ola('poliza')"
                  style="color: #d34659; cursor: pointer"
                >
                  Política de envío de la póliza de seguro.</span
                >
              </div>

              <div>
                <mat-checkbox
                  [checked]="renovacion"
                  class="example-margin"
                  (change)="conditions('renovacion')"
                ></mat-checkbox>
                Acepto las
                <span
                  (click)="ola('renovacion')"
                  style="color: #d34659; cursor: pointer"
                  >Condiciones de renovación automática.</span
                >
              </div>
            </div>
          </div>
          <div class="botones">
            <p></p>
            <div class="row-simple">
              <button class="btn-atras" mat-button matStepperPrevious>
                VOLVER
              </button>
              <button
                class="btn-siguiente"
                mat-raised-button
                (click)="comprar()"
                [disabled]="!thirdValidator()"
                matStepperNext
              >
                PAGAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>

<div class="carga" *ngIf="cargando">
  <div class="style-100">
    <mat-spinner></mat-spinner>
  </div>
</div>
