import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PlanesOhRoutingModule } from './planes-oh-routing.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { PlanFamiliarComponent } from './components/plan-familiar/plan-familiar.component';
import { LandingComponent } from './components/landing/landing.component';
import { SelectPlanComponent } from './components/select-plan/select-plan.component';
import { PlanIndividualComponent } from './components/plan-individual/plan-individual.component';
import { PlanOtrosComponent } from './components/plan-otros/plan-otros.component';

@NgModule({
  declarations: [
    LandingComponent,
    SelectPlanComponent,
    PlanIndividualComponent,
    PlanFamiliarComponent,
    PlanOtrosComponent,
  ],
  imports: [
    CommonModule,
    PlanesOhRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
})
export class PlanesOhModule {}
