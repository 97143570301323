<app-swiper [page]="'test-covid'"></app-swiper>
<section class="sintomas">
  <h2>Conoce aquí los síntomas más graves de la COVID-19</h2>
  <p class="mb-5">
    Recuerda que no todas las personas presentan síntomas, por ello es
    importante seguir las medidas de prevención
  </p>
  <div class="sintomas-container">
    <div class="sintoma" *ngFor="let sintoma of sintomas">
      <img style="width: 5rem; height: 5rem" src="{{ sintoma.icon }}" alt="" />
      <h3>{{ sintoma.title }}</h3>
      <p>{{ sintoma.desc }}</p>
    </div>
  </div>
</section>
<section class="prueba">
  <h2>Solicita tu prueba COVID-19 para ti y tu familia</h2>
  <p>¡Beneficio exclusivo con oh!</p>
  <div class="container">
    <img class="img" src="/assets/png/doctor.png" alt="" />
    <div class="prueba-container">
      <div class="resalt">RECOMENDADO</div>
      <h2>Prueba Antígena</h2>
      <p class="subtitle">¡Beneficio OH!</p>
      <img src="/assets/icons/oh-logo.svg" alt="" />
      <h1>S./ 150.00</h1>
      <h3>Antes: <del>S./ 200</del></h3>
      <div class="beneficios">
        <p>Pruebas en laboratorios Red salud</p>
        <p>Pruebas a domicilio</p>
        <p>Resultados en 3 horas</p>
        <p>Más del 95% de efectividad</p>
        <p>Resultados desde la plataforma</p>
      </div>
      <div class="cantidad">
        <Button (click)="minus()" class="btn">-</Button>
        <div class="numero">
          <p>{{ num }}</p>
        </div>
        <Button (click)="plus()" class="btn">+</Button>
      </div>
      <button class="comprar" (click)="comprar()">COMPRAR</button>
    </div>
  </div>
</section>
<section class="baner">
  <h2>Accede a tus resultados desde nuestra plataforma</h2>
  <p>Descubre los descuentos con tu tarjeta Oh!</p>
  <img src="/assets/png/covid-baner.png" alt="" />
</section>
<section class="preguntas">
  <h2>Preguntas frecuentes</h2>
  <expanded [page]="'test-covid'"></expanded>
</section>
