<section class="start">
  <img src="assets/png/select-plan.png" alt="" />
  <div class="options">
    <div class="title">
      <h2>
        {{ plan }}
        <br />
        <span>S/ {{ precio | number: "1.2-2" }} /mes/persona</span>
      </h2>
    </div>
    <app-radio></app-radio>
    <button mat-button (click)="go()">COMPRAR</button>
  </div>
</section>
