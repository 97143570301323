<form [formGroup]="formGroup">
  <h2 mat-dialog-title>Editar afiliado</h2>
  <div mat-dialog-content>
    <div class="form-group">
      <div class="form-group-child">
        <mat-label>Tipo de documento</mat-label>
        <mat-form-field appearance="outline" class="input">
          <mat-select
            [(ngModel)]="tipodoc"
            (ngModelChange)="set_max()"
            formControlName="tipodoc"
          >
            <mat-option value="2">DNI</mat-option>
            <mat-option value="4">PASAPORTE</mat-option>
            <mat-option value="1">RUC</mat-option>
            <mat-option value="3">CARNET DE EXTRANJERÍA</mat-option>
            <mat-option value="5">CÉDULA DE INDENTIFICACIÓN</mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.controls.tipodoc.hasError('required')">
            Seleccione un tipo de documento.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Número de documento</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_numDoc"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
          (ngModelChange)="validarDNI()"
        >
          <input
            [(ngModel)]="afiliado.user_numDoc"
            matInput
            type="number"
            [maxlength]="max"
            oninput=" if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
            required
            formControlName="dni"
          />
          <mat-error *ngIf="formGroup.controls.dni.hasError('required')">
            Por favor ingresa un número de documento válido.
          </mat-error>
          <mat-icon matSuffix *ngIf="!loading">recent_actors</mat-icon>
          <div class="search" *ngIf="loading" matSuffix>
            <div class="lds-dual-ring"></div>
          </div>
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Teléfono</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_telf"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_telf"
            matInput
            required
            formControlName="telefono"
          />
          <mat-error *ngIf="formGroup.controls.telefono.hasError('required')">
            Por favor completa este campo.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Correo</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_email"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_email"
            matInput
            type="email"
            required
            formControlName="email"
          />
          <mat-error *ngIf="formGroup.controls.email.hasError('email')">
            Por favor completa este campo.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Fecha de nacimiento</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="fecha"
            [ngModelOptions]="{ standalone: true }"
            ngDefaultControl
          />
          <mat-error *ngIf="formGroup.controls.fecha.hasError('required')">
            Por favor completa este campo.
          </mat-error>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group-child">
        <mat-label>Primer nombre</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_nom1"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [ngModel]="afiliado.user_nom1 | titlecase"
            matInput
            type="text"
            required
            formControlName="nomb1"
          />
          <mat-error *ngIf="formGroup.controls.nomb1.hasError('required')">
            Por favor completa este campo.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Segundo nombre</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_nom2"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [ngModel]="afiliado.user_nom2 | titlecase"
            [ngModelOptions]="{ standalone: true }"
            matInput
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Apellido paterno</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_ape1"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [ngModel]="afiliado.user_ape1 | titlecase"
            matInput
            type="text"
            required
            formControlName="ape1"
          />
          <mat-error *ngIf="formGroup.controls.ape1.hasError('required')">
            Por favor completa este campo.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Apellido materno</mat-label>
        <mat-form-field
          appearance="outline"
          [ngModel]="afiliado.user_ape2 | titlecase"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_ape2"
            matInput
            type="text"
            required
            formControlName="ape2"
          />
          <mat-error *ngIf="formGroup.controls.ape2.hasError('required')">
            Por favor completa este campo.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group-child">
        <mat-label>Dirección</mat-label>
        <mat-form-field
          appearance="outline"
          [(ngModel)]="afiliado.user_direcc"
          [ngModelOptions]="{ standalone: true }"
          ngDefaultControl
        >
          <input
            [(ngModel)]="afiliado.user_direcc"
            matInput
            type="text"
            required
            formControlName="direccion"
          />
          <mat-error *ngIf="formGroup.controls.direccion.hasError('required')">
            Por favor completa este campo.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group-child">
        <mat-label>Locación</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            (click)="modal()"
            [(ngModel)]="ubigeoString"
            formControlName="ubigeo"
            readonly
            required
          />
          <mat-error *ngIf="formGroup.controls.ubigeo.hasError('required')">
            Por favor completa este campo.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="actions">
    <button class="cancel" mat-button (click)="close()">Cancelar</button>
    <button
      class="save"
      mat-button
      type="submit"
      (click)="aea()"
      [disabled]="!validator()"
    >
      Guardar
    </button>
  </div>
</form>
