<swiper
  class="web"
  [slidesPerView]="1"
  [loop]="true"
  [navigation]="true"
  [pagination]="{ clickable: true }"
  [autoplay]="{ delay: 2500 }"
  [allowTouchMove]="false"
  [speed]="1500"
>
  <div *ngIf="page == 'planes'">
    <ng-template *ngFor="let image of PlansData" swiperSlide>
      <img src="{{ image }}" alt="" />
    </ng-template>
  </div>

  <div *ngIf="page == 'test-covid'">
    <ng-template *ngFor="let image of TestCovidData" swiperSlide>
      <img src="{{ image }}" alt="" />
    </ng-template>
  </div>
</swiper>

<swiper
  class="resp"
  [slidesPerView]="1"
  [loop]="true"
  [navigation]="true"
  [pagination]="{ clickable: true }"
  [autoplay]="{ delay: 2500 }"
>
  <div *ngIf="page == 'planes'">
    <ng-template *ngFor="let image of PlansDataResp" swiperSlide>
      <img src="{{ image }}" alt="" />
    </ng-template>
  </div>

  <div *ngIf="page == 'test-covid'">
    <ng-template *ngFor="let image of TestCovidDataResp" swiperSlide>
      <img src="{{ image }}" alt="" />
    </ng-template>
  </div>
</swiper>
