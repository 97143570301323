import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutPaymentComponent } from './components/checkout-payment/checkout-payment.component';
import { LandingComponent } from './components/landing/landing.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: LandingComponent,
  // },
  {
    path: 'checkout',
    component: CheckoutPaymentComponent,
  },
  // {
  //   path: '**',
  //   component: CheckoutPaymentComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CovidOhRoutingModule {}
