<div *ngIf="data.message == 'error'">
  <h3>Error</h3>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <circle
      class="path circle"
      fill="none"
      stroke="#D06079"
      stroke-width="6"
      stroke-miterlimit="10"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
    <line
      class="path line"
      fill="none"
      stroke="#D06079"
      stroke-width="6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      x1="34.4"
      y1="37.9"
      x2="95.8"
      y2="92.3"
    />
    <line
      class="path line"
      fill="none"
      stroke="#D06079"
      stroke-width="6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      x1="95.8"
      y1="38"
      x2="34.4"
      y2="92.2"
    />
  </svg>
  <p>Error en la transacción, intente más tarde.</p>
  <mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Aceptar</button>
  </mat-dialog-actions>
</div>

<div *ngIf="data.message == 'success'">
  <h3>Pago Exitoso</h3>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <circle
      class="path circle"
      fill="none"
      stroke="#73AF55"
      stroke-width="6"
      stroke-miterlimit="10"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
    <polyline
      class="path check"
      fill="none"
      stroke="#73AF55"
      stroke-width="6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      points="100.2,40.2 51.5,88.8 29.8,67.5 "
    />
  </svg>
  <p class="success">Transacción Exitosa</p>
</div>

<div *ngIf="data.message === 'loading'" class="loading">
  <h3>Pagando</h3>
  <img src="assets/images/loading.svg" alt="" />
  <p>La transacción esta procesando.</p>
</div>

<div *ngIf="data.message == 'thanks'" class="thanks">
  <img src="assets/icons/hands.svg" alt="" />
  <h3>¡Recibimos tu solicitud de afiliación!</h3>
  <p>
    En menos de 24 horas te enviaremos un correo confirmando tu pago. En caso de
    no recibir el correo revisa tu bandeja de no deseado. Para más información
    comunícate a los siguientes números:
  </p>
  <div class="contacto">
    <div class="contacto-item">
      <img src="assets/icons/wsp.svg" alt="" />
      <div class="contacto-tem--text">
        <p class="titulo">Whatsapp</p>
        <p>947186478</p>
      </div>
    </div>
    <div class="contacto-item">
      <img src="assets/icons/tel.svg" alt="" />
      <div class="contacto-tem--text">
        <p class="titulo">Llámanos</p>
        <p>(01)445 3019</p>
      </div>
    </div>
  </div>
  <mat-dialog-actions>
    <button mat-button (click)="aceptar()">Aceptar</button>
  </mat-dialog-actions>
</div>

<div *ngIf="data.message == 'recurrent'">
  <h3>Error</h3>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <circle
      class="path circle"
      fill="none"
      stroke="#D06079"
      stroke-width="6"
      stroke-miterlimit="10"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
    <line
      class="path line"
      fill="none"
      stroke="#D06079"
      stroke-width="6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      x1="34.4"
      y1="37.9"
      x2="95.8"
      y2="92.3"
    />
    <line
      class="path line"
      fill="none"
      stroke="#D06079"
      stroke-width="6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      x1="95.8"
      y1="38"
      x2="34.4"
      y2="92.2"
    />
  </svg>
  <p class="error">Esta persona ya se encuentra afiliada a este plan.</p>
</div>

<div *ngIf="data.message == 'envio'">
  <h2>Políticas de envío de comunicaciones comerciales</h2>
  <p>
    Autorizo a Health Care Administration Red Salud S.A.C. a utilizar mis Datos
    Personales y que los mismos sean tratados por terceros, para que me sean
    ofrecidos beneficios, productos y servicios de Health Care Administration
    Red Salud S.A.C., a través de cualquier medio de comunicación. Declaro
    conocer que el no conceder esta autorización no afectará la prestación del
    servicio contratado.
  </p>
  <button mat-button (click)="onNoClick()">Aceptar</button>
</div>

<div *ngIf="data.message == 'poliza'">
  <h2>Política de envío de la póliza de seguro</h2>
  <p>
    Autorización para envío de la póliza electrónica. Autorizo para que el
    condionacio de mi plan de salud se envíe al correo electrónico aquí
    consignado. Asimismo, declaro conocer y aceptar que: <br />
    1. El Plan de Salud comprende los documentos que forman parte de ella, así
    como cualquier comunicación relacionada a ella de cualquier índole.<br />
    2. El Plan de Salud será enviado por correo electrónico. <br />3. La forma
    de envío es inmediato, son las ventajas de este procedimiento.
    Consideraciones básicas: Recuerde que debe abrir y leer las comunicaciones
    electrónicas, archivos y links adjuntos, revisar su correo electrónico
    (bandejas de entrada y de “no deseados”), verificar la política de filtro o
    bloqueo de su proveedor de correo electrónico, mantener activa su cuenta y
    no bloquear nunca a afiliaciones1@red-salud.com.
  </p>
  <button mat-button (click)="onNoClick()">Aceptar</button>
</div>

<div *ngIf="data.message == 'renovacion'">
  <h2 mat-dialog-title>Condiciones de renovación automática</h2>

  <mat-dialog-content>
    <p>
      Health Care Administration Red Salud S.A.C realiza el cargo mensual en el
      medio de pago que he utilizado para adquirir el Plan de Salud y para la
      renovación de la vigencia del Plan de Salud. Asimismo, el contratante es
      responsable de comunicar a Health Care Administration Red Salud S.A.C en
      caso se modifique o anule el medio de pago empleado (por ejemplo: Cambio
      en el número de la tarjeta de crédito o débito), a fin de actualizar el
      nuevo medio de pago y vincularlo al Plan de Salud. En caso de no
      realizarlo, Health Care Administration Red Salud S.A.C no podrá realizar
      el cargo de la prima, y el Plan de Salud podría extinguirse por
      incumplimiento de pago de la prima conforme lo establecido en la normativa
      vigente. En este sentido, Health Care Administration Red Salud S.A.C no
      será responsable por cualquier siniestro ocurrido con posterioridad a la
      fecha de extinción del Plan de Salud, o en su defecto, en caso sea
      imposible optar por su renovación. Usted podrá revocar la autorización del
      cargo recurrente de su Tarjeta de Crédito y/o Débito llamando al 445-3019
      o escribiéndonos al correo info@red-salud.com.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Aceptar</button>
  </mat-dialog-actions>
</div>

<div mg></div>
