interface Cobertura {
  icon: string;
  info: string;
  title: string;
  text: string;
  clasico: string;
  premium: string;
}

export const Coberturas: Cobertura[] = [
  {
    icon: '/assets/icons/doctor.svg',
    info: '/assets/icons/info.svg',
    title: 'Atención ambulatoria',
    text: 'Medicina General, Pediatría, Ginecología, Urología.',
    clasico: '/assets/icons/uncheck.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/teleconsultas.svg',
    info: '/assets/icons/info.svg',
    title: 'Teleconsultas',
    text: 'Medicina General, Pediatría, Ginecología, Urología.',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/orientacion-telefonica.svg',
    info: '/assets/icons/info.svg',
    title: 'Orientación telefónica',
    text: 'Medicina General, Pediatría y Psicología (no cubre medicamentos).',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/genericos.svg',
    info: '',
    title: 'Medicamentos genéricos',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/test-covid.svg',
    info: '',
    title: 'Test Virtual COVID-19',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/tratamiento.svg',
    info: '',
    title: 'Tratamiento para el COVID-19',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/examenes.svg',
    info: '',
    title: 'Exámenes de laboratorio',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: 'assets/icons/x-ray.svg',
    info: '/assets/icons/info.svg',
    title: 'Rayos X',
    text: 'Rayos X de brazos, piernas, hombros, manos y tórax.',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
];

export const CoberturasClasic: Cobertura[] = [
  {
    icon: '/assets/icons/teleconsultas.svg',
    info: '/assets/icons/info.svg',
    title: 'Teleconsultas',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/orientacion-telefonica.svg',
    info: '/assets/icons/info.svg',
    title: 'Orientación telefónica',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/genericos.svg',
    info: '/assets/icons/info.svg',
    title: 'Medicamentos genéricos',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/test-covid.svg',
    info: '',
    title: 'Test Virtual COVID-19',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/tratamiento.svg',
    info: '',
    title: 'Tratamiento para el COVID-19',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: '/assets/icons/examenes.svg',
    info: '/assets/icons/info.svg',
    title: 'Exámenes de laboratorio',
    text: '',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
  {
    icon: 'assets/icons/x-ray.svg',
    info: 'assets/icons/info.svg',
    title: 'Rayos X',
    text: 'Rayos X de brazos, piernas, hombros, manos y tórax.',
    clasico: '/assets/icons/check.svg',
    premium: '/assets/icons/check.svg',
  },
];
