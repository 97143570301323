import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { Ubigeo, User } from '../../models/user.model';
import { DialogService } from '../../services/dialog/dialog.service';
import { UtilService } from '../../services/utils/utils.service';
import { ModalUbigeoComponent } from '../modal-ubigeo/modal-ubigeo.component';

@Component({
  selector: 'app-modal-add-afiliado',
  templateUrl: './modal-add-afiliado.component.html',
  styleUrls: ['./modal-add-afiliado.component.scss'],
})
export class ModalAddAfiliadoComponent implements OnInit {
  public precio: number;

  public id_plan: number;

  public loading: boolean = false;

  public fecha: Date;

  public max: string = '';
  public tipodoc: string = '';

  public afiliado: User = {
    user_id: 0,
    user_tipoDoc_id: 0,
    user_numDoc: '',
    user_nom1: '',
    user_nom2: '',
    user_ape1: '',
    user_ape2: '',
    user_direcc: '',
    user_telf: '',
    user_email: '',
    user_ubg: '',
    user_fech_nac: '',
    user_sexo: '',
    user_tipo: '',
    user_plan: 0,
    user_idprovedor: 0,
  };

  public ubigeo: Ubigeo = {
    id: '',
    departamento: '',
    provincia: '',
    distrito: '',
  };

  public ubigeoString: string;

  public formGroup: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalAddAfiliadoComponent>,
    public dialog: MatDialog,
    private service: UtilService,
    private _formBuilder: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: User[]
  ) {}

  ngOnInit(): void {
    this.afiliado.user_fech_nac = new Date().toLocaleDateString();

    this.precio = this.service.getPrice();

    this.setIdPlan(this.precio);

    this.formGroup = this._formBuilder.group({
      tipodoc: ['', Validators.required],
      dni: ['', Validators.required],
      telefono: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      nomb1: ['', Validators.required],
      ape1: ['', Validators.required],
      ape2: ['', Validators.required],
      direccion: ['', Validators.required],
      fecha: ['', Validators.required],
      ubigeo: ['', Validators.required],
    });
  }

  set_max() {
    this.afiliado.user_numDoc = '';

    switch (this.tipodoc) {
      case '2':
        this.max = '8';
        break;
      case '3':
      case '4':
        this.max = '12';
        break;
      case '1':
        this.max = '20';
        break;
      case '5':
        this.max = '10';
        break;
    }
  }

  setIdPlan(precio: number) {
    switch (precio) {
      case 35:
        this.id_plan = 255;
        // console.log(this.id_plan);
        break;
      case 55:
        this.id_plan = 256;
        break;
    }
  }

  validatefecha() {
    // console.log(this.fecha);

    let value: boolean = false;

    if (!this.fecha) {
      value = true;
    }

    return value;
  }

  validarDNI() {
    if (
      this.afiliado.user_numDoc.length === parseInt(this.max) ||
      this.afiliado.user_numDoc.length === 11
    ) {
      this.loading = true;
      return this.service
        .listUser(this.afiliado.user_numDoc, this.id_plan, 2)
        .subscribe(
          (datos) => {
            // console.log(datos);
            this.loading = false;
            if (datos != null) {
              this.afiliado.user_id = datos['user_id'];
              this.afiliado.user_tipoDoc_id = datos['user_tipoDoc_id'];
              this.afiliado.user_numDoc =
                datos['user_numDoc'] != ''
                  ? datos['user_numDoc']
                  : this.afiliado.user_numDoc;
              this.afiliado.user_nom1 = datos['user_nom1'];
              this.afiliado.user_nom2 = datos['user_nom2'];
              this.afiliado.user_ape1 = datos['user_ape1'];
              this.afiliado.user_ape2 = datos['user_ape2'];
              this.afiliado.user_direcc = datos['user_direcc'];
              this.afiliado.user_telf = datos['user_telf'];
              this.afiliado.user_email = datos['user_email'];
              this.afiliado.user_ubg = datos['user_ubg'];
              this.afiliado.user_fech_nac = datos['user_fech_nac'];

              this.fecha = new Date(`${this.afiliado.user_fech_nac}T00:00:00`);

              this.afiliado.user_sexo = datos['user_sexo'];
              // this.user.user_plan = 98;
              (this.afiliado.user_plan = this.precio == 55 ? 256 : 255),
                (this.afiliado.user_tipo = '1');
            } else {
              this.loading = false;
              this.afiliado.user_tipoDoc_id = parseInt(this.tipodoc);
            }
          },
          (error) => {
            this.loading = false;
            this.dialogService.material('recurrent', 'planes');
            this.dialogRef.close();
          }
        );
    }
  }

  modal() {
    const dialogRef = this.dialog.open(ModalUbigeoComponent, {
      width: '80%',
      data: this.ubigeo,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ubigeo = result;
      // if (this.ubigeo.departamento === '') {
      this.ubigeoString = `${this.ubigeo.departamento}/${this.ubigeo.provincia}/${this.ubigeo.distrito}`;
      this.afiliado.user_ubg = this.ubigeo.id;
      // }
      // console.log(this.ubigeo);
    });
  }

  setfecha() {
    let t = this.fecha.toLocaleDateString().split('/');

    let dia = t[0].length === 1 ? `0${t[0]}` : `${t[0]}`;
    let mes = t[1].length === 1 ? `0${t[1]}` : `${t[1]}`;
    let anio = t[2];

    this.afiliado.user_fech_nac = `${anio}${mes}${dia}`;

    // console.log(this.afiliado);
  }

  close(): void {
    this.dialogRef.close();
  }

  validator(): boolean {
    let value = this.formGroup.status == 'VALID';

    return value;
  }

  aea() {
    let t = this.fecha.toLocaleDateString().split('/');

    let dia = t[0].length === 1 ? `0${t[0]}` : `${t[0]}`;
    let mes = t[1].length === 1 ? `0${t[1]}` : `${t[1]}`;
    let anio = t[2];

    this.afiliado.user_fech_nac = `${anio}${mes}${dia}`;

    if (
      this.afiliado.user_numDoc == '' ||
      this.afiliado.user_nom1 == '' ||
      this.afiliado.user_ape1 == '' ||
      this.afiliado.user_ape2 == '' ||
      this.afiliado.user_direcc == '' ||
      this.afiliado.user_ubg == ''
    ) {
      // alert('Completa todos los datos necesarios uwu');
    } else {
      // this.afiliado.user_plan = this.precio == 55 ? 100 : 99;
      (this.afiliado.user_plan = this.precio == 55 ? 256 : 255),
        (this.afiliado.user_tipo = '2');
      this.data.push(this.afiliado);
      this.dialogRef.close();
      // console.log(this.data);
    }
  }
}
