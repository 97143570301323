import { Pagos } from './../../../../shared/models/payment-model';
import {
  AseguradoFinal,
  DocumentPayment,
  DocumentPrincipal,
  DocumentSecondary,
} from './../../../../shared/models/asegurado-model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AseguradoModel } from 'src/app/shared/models/asegurado-model';
import { ValidateCardPipe } from 'src/app/shared/pipes/validateCard/validate-card.pipe';
import { Router } from '@angular/router';
import { CovidService } from 'src/app/shared/services/covid/covid.service';
import { UtilService } from 'src/app/shared/services/utils/utils.service';
import { TitleCasePipe } from '@angular/common';
import { PhonePipe } from 'src/app/shared/pipes/validation/phone.pipe';
import { ModalUbigeoComponent } from 'src/app/shared/material/modal-ubigeo/modal-ubigeo.component';
import { MatDialog } from '@angular/material/dialog';
import { Ubigeo } from 'src/app/shared/models/user.model';
import { CheckoutPayment } from 'src/app/shared/models/payment-model';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { DialogService } from 'src/app/shared/services/dialog/dialog.service';
import { ValidateMYPipe } from 'src/app/shared/pipes/validate-my.pipe';
@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
})
export class CheckoutPaymentComponent implements OnInit {
  public datoInicial: number;
  public loading: boolean = false;
  public step: number = 0;

  public maxLengthType: number = 11;
  public searchType: Boolean = false;
  public dataPrincipal: DocumentPrincipal = {
    id: 0,
    type: '',
    number: null,
    first_name: '',
    seconds_name: '',
    first_lastname: '',
    second_lastname: '',
  };

  public showCenter: Boolean = false;
  public dataSecondary: DocumentSecondary = {
    mail: '',
    phone: null,
    general: '',
    direction: '',
    atention_center: 0,
  };
  public ubigeo: Ubigeo = {
    id: '',
    departamento: '',
    provincia: '',
    distrito: '',
  };

  public dataAfiliados: Array<AseguradoModel> = [];

  public creditCard: DocumentPayment = {
    number_credit: null,
    type: '',
    fullname: '',
    expiration: '',
    cvv: null,
  };

  public firstFormGroup: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public fourFormGroup: FormGroup;
  public addQuestion: number;
  public validatorPlan: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private covidService: CovidService,
    private utilService: UtilService,
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.datoInicial = this.covidService.getCant();
    for (let i = 0; i < this.datoInicial; i++) {
      this.dataAfiliados.push({
        id: 0,
        user_id: i + 1,
        user_numDoc: null,
        user_nom1: '',
        user_ape1: '',
        user_ape2: '',
        user_fech_nac: '',
        user_email: '',
        user_plan: null,
      });
    }
    this.firstFormGroup = this.formBuilder.group({
      type: ['', Validators.required],
      number: ['', Validators.required],
      first_name: ['', Validators.required],
      first_lastname: ['', Validators.required],
      second_lastname: ['', Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      mail: ['', Validators.required],
      phone: ['', Validators.required],
      ubication: ['', Validators.required],
      direction: ['', Validators.required],
    });
    this.thirdFormGroup = this.formBuilder.group({
      // thirdCtrl: [
      //   '',
      //   Validators.required && this.step == this.dataAfiliados.length,
      // ],
    });
    this.fourFormGroup = this.formBuilder.group({
      numbercredit: ['', Validators.required],
      fullname: ['', Validators.required],
      expiration: ['', Validators.required],
      cvv: ['', Validators.required],
    });
  }

  fourClick(): void {
    this.dialogService.material('loading', 'seguros');
    let pagos: Pagos = {
      codplan: 'Pruebas-Oh-002',
      email: this.dataSecondary.mail,
      name: `${this.creditCard.fullname}`,
      document: this.dataPrincipal.number.toString(),
      number: this.creditCard.number_credit.toString(),
      expMonth: this.creditCard.expiration.split('/')[0],
      expYear: this.creditCard.expiration.split('/')[1],
      typeCard: this.creditCard.type,
      typeDocument: this.dataPrincipal.type,
      cvv: this.creditCard.cvv.toString(),
      line1: this.dataSecondary.direction,
      postalCode: '+51',
      city: this.ubigeo.distrito,
      state: this.ubigeo.distrito,
      country: 'PE',
      phone: this.dataSecondary.phone,
      quantity: this.dataAfiliados.length.toString(),
    };

    let data = this.dataAfiliados;

    let infoPersona: AseguradoFinal = {
      user_id: this.dataPrincipal.id, //será 0 cuando no existe el asegurado en bd
      user_tipoDoc_id: 2, //2:dni, 1:carnet extrangería
      user_numDoc: this.dataPrincipal.number.toString(),
      user_nom1: this.dataPrincipal.first_name,
      user_nom2: this.dataPrincipal.seconds_name,
      user_ape1: this.dataPrincipal.first_lastname,
      user_ape2: this.dataPrincipal.second_lastname,
      user_direcc: this.dataSecondary.direction,
      user_telf: this.dataSecondary.phone,
      user_email: this.dataSecondary.mail,
      user_ubg: this.ubigeo.id,
      user_fech_nac: '', //mantenerlo vacio  si no tiene data
      user_sexo: '', //mantenerlo vacio si no tiene data
      user_tipo: '1', // 1: contratante, 2: afiliado
      user_plan: 254, // PLANES-> 255:TELESALUD OH , 256: TELESALUD FULL , 254:PRUEBAS ANTIGENAS
      user_idproveedor: this.dataSecondary.atention_center, //mantener en 0  si no tiene data
      user_parent: 1,
    };

    this.paymentService.sendDataCovid(pagos, data, infoPersona).subscribe(
      (datos) => {
        if (datos) {
          this.dialogService.close();
          this.dialogService.material('thanks', 'seguros');
        } else {
          this.dialogService.close();
          this.dialogService.material('error', 'seguros');
        }
      },
      (error) => {
        // console.log('error');
      }
    );
  }

  back() {
    window.history.back();
  }

  validarDNI() {
    // if (`${this.dataPrincipal.number}`.length === 8) {
    //   return this.utilService
    //     .listUser(this.dataPrincipal.number.toString())
    //     .subscribe(
    //       (datos) => {
    //         if (datos != null) {
    //           this.dataPrincipal.id = datos['user_id'];
    //           this.dataPrincipal.first_name = new TitleCasePipe().transform(
    //             datos['user_nom1']
    //           );
    //           this.dataPrincipal.seconds_name = new TitleCasePipe().transform(
    //             datos['user_nom2']
    //           );
    //           this.dataPrincipal.first_lastname = new TitleCasePipe().transform(
    //             datos['user_ape1']
    //           );
    //           this.dataPrincipal.second_lastname = new TitleCasePipe().transform(
    //             datos['user_ape2']
    //           );
    //           this.dataSecondary.mail = new TitleCasePipe().transform(
    //             datos['user_email']
    //           );
    //           this.dataSecondary.phone = new PhonePipe().transform(
    //             datos['user_telf']
    //           );
    //           this.dataSecondary.direction = new TitleCasePipe().transform(
    //             datos['user_direcc']
    //           );
    //           this.validarUbigeo(this.dataPrincipal.number.toString());
    //           this.searchType = false;
    //         }
    //       },
    //       (error) => {
    //         this.searchType = false;
    //       }
    //     );
    // }
  }

  validarUbigeo(dni: string) {
    return this.utilService.listUbigeo(dni).subscribe(
      (datos) => {
        if (datos != null) {
          // console.log(datos);
          this.ubigeo.departamento = datos['departamento'];
          this.ubigeo.provincia = datos['provincia'];
          this.ubigeo.distrito = datos['distrito'];
          this.dataSecondary.general = `${this.ubigeo.departamento} / ${this.ubigeo.provincia} / ${this.ubigeo.distrito}`;
          this.ubigeo.id = datos['id'];
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  selectUbigeo() {
    const dialogRef = this.dialog.open(ModalUbigeoComponent, {
      width: '65%',
      data: this.ubigeo,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ubigeo = result;
      this.dataSecondary.general = `${this.ubigeo.departamento} / ${this.ubigeo.provincia} / ${this.ubigeo.distrito}`;
      this.ubigeo.id = result['id'];
      // console.log(result, this.ubigeo.id);
    });
  }

  firstValidator(): Boolean {
    let value =
      (this.dataPrincipal.type === 'dni' ||
        this.dataPrincipal.type === 'ruc') &&
      `${this.dataPrincipal.number}`.length > 6 &&
      this.dataPrincipal.first_name.length > 1 &&
      this.dataPrincipal.first_lastname.length > 1 &&
      this.dataPrincipal.second_lastname.length > 1;
    return value;
  }

  secondValidator(): Boolean {
    let value =
      this.dataSecondary.mail.includes('@') &&
      this.dataSecondary.mail.includes('.') &&
      `${this.dataSecondary.phone}`.length > 6 &&
      this.dataSecondary.direction.length > 3 &&
      this.dataSecondary.atention_center !== null &&
      (this.addQuestion === 1 || this.addQuestion === 0);
    return value;
  }

  thirdValidator(): Boolean {
    let value;
    if (this.dataAfiliados.length > 0) {
      value = this.dataAfiliados.length + 1 > this.step;
    } else {
      value = true;
    }
    return !value;
  }

  fourValidator(): Boolean {
    let value =
      `${this.creditCard.number_credit}`.length > 14 &&
      this.creditCard.type.length > 3 &&
      this.creditCard.fullname.length > 4 &&
      this.creditCard.expiration.length === 7 &&
      `${this.creditCard.cvv}`.length > 2 &&
      this.creditCard.cvv !== null;
    return value;
  }

  addPerson(i: number): void {
    this.addQuestion = i;
    if (i === 1) {
      this.dataAfiliados[0] = {
        id: 0,
        user_id: 1,
        user_numDoc: this.dataPrincipal.number,
        user_nom1: this.dataPrincipal.first_name,
        user_ape1: this.dataPrincipal.first_lastname,
        user_ape2: this.dataPrincipal.second_lastname,
        user_fech_nac: '',
        user_email: this.dataSecondary.mail,
        user_plan: this.validatorPlan,
      };
    }
    if (this.dataAfiliados.length === 0) {
      this.datoInicial = 1;
      this.dataAfiliados.push({
        //ojo aqui
        id: 0,
        user_id: 1,
        user_numDoc: this.dataPrincipal.number,
        user_nom1: this.dataPrincipal.first_name,
        user_ape1: this.dataPrincipal.first_lastname,
        user_ape2: this.dataPrincipal.second_lastname,
        user_fech_nac: '',
        user_email: this.dataSecondary.mail,
        user_plan: this.validatorPlan,
      });
    }
  }

  onChange(text: string): void {
    if (this.dataPrincipal.type === 'dni') {
      this.maxLengthType = 8;
      if (
        this.dataPrincipal.number !== null &&
        this.dataPrincipal.number.toString().length === 8
      ) {
        this.searchType = true;
      }
    } else {
      this.maxLengthType = 11;
    }
  }

  onChangeSelect(text: string): void {
    // console.log(this.dataSecondary.atention_center);
  }

  onChangeButton(data: Boolean): void {
    this.validatorPlan = 254;
    // this.validatorPlan = data ? 98 : 0;
    this.showCenter = data;

    // // console.log(this.dataSecondary.atention_center);
    // this.dataSecondary.atention_center = 0;
  }

  addAfiliado(): void {
    this.dataAfiliados.push({
      id: 0,
      user_id: this.dataAfiliados.length + 1,
      user_numDoc: null,
      user_nom1: '',
      user_ape1: '',
      user_ape2: '',
      user_fech_nac: '',
      user_email: '',
      user_plan: this.validatorPlan,
    });

    this.datoInicial = this.dataAfiliados.length;
  }

  resultAfiliacion(data: AseguradoModel): void {
    this.dataAfiliados[data.user_id - 1] = data;
    this.step++;
  }

  cancelAfiliacion(i: number): void {
    this.setStep(i - 1);
  }

  editAfiliacion(i: number): void {
    this.setStep(i);
  }

  deleteAfiliacion(id: number): void {
    this.dataAfiliados.splice(id - 1, 1);
    this.datoInicial = this.dataAfiliados.length;
    for (let i = 0; i < this.dataAfiliados.length; i++) {
      this.dataAfiliados[i].user_id = i + 1;
    }
    this.setStep(1);
  }

  getTypeCreditCard(): void {
    this.creditCard.type = new ValidateCardPipe().transform(
      this.creditCard.number_credit.toString()
    );
    // // console.log(this.creditCard.number_credit);
    // // console.log(this.creditCard.type);
  }

  validateMMYY() {
    if (this.creditCard.expiration.length == 2) {
      this.creditCard.expiration += '/';
    }

    // if (new ValidateMYPipe().transform(this.creditCard.expiration)) {
    //   // console.log('true');
    // } else {
    //   // // console.log('false');
    // }
  }

  setStep(index: number): void {
    this.step = index;
  }
}
